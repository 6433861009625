let awsRegion = 'us-east-1';

export default {
 api_urls: {
   reporting: 'https://6zdyf9pigd.execute-api.us-east-1.amazonaws.com/api',
   geojson: 'https://sj83i20odh.execute-api.us-east-1.amazonaws.com/api',
   overlays: 'https://ffasqoi4yg.execute-api.us-east-1.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 191,
 crm_base_url: 'https://riotinto.crm.testing.forwoodsafety.com',
 forwood_id_url: 'https://id.testing.riotinto.forwoodsafety.com?redirect_uri=https://geoeditor.testing.riotinto.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.testing.riotinto.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-east-1_mTKeit2kD',
   userPoolWebClientId: 'is4arap5ibdr116e0cc95r1s1',
   cookieStorage: {
     domain: '.testing.riotinto.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'True',
 env: 'testing'
};
